blockquote {
    border-left: .25em solid
}

.pJst {
    width: 100%;
    text-align: justify;
    font-size: 1rem;
    padding: 10px;
}

.codeJst {
    width: 100%;
    text-align: justify;
    font-size: 1rem;
}

.divCenter {
    text-align: center;
}

.inputEmail {
    width: 400px;
}

.hiddenElm {
    display:none;
}

.mrgBtm {
    margin-bottom: 50;
}

.mrgRightBtn {
    margin-right: 10px;
    margin-bottom: 10px;
}

.card-body {
    height: 200px;
}

.otherRead {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.padingThumb {
    padding: 10px;
}

.tdTitle {
    width: 150px;
}

.tdTitleName {
    font-weight: bold;
}

.pJustify {
    text-align: justify;
}